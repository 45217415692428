import type { RouteLocationRaw } from "vue-router";
import { useCollectionStore, type CollectionStore } from "./useCollectionStore";

interface Navigation {
  current: Ref<string | null>;
  previous: Ref<string | null>;
  next: Ref<string | null>;
  position: Ref<number | null>;
  count: Ref<number | null>;
  setStore: (talents: string[]) => void;
  clearStore: () => void;
  setNavigation: () => void;
}

const emptyNavigation: Navigation = {
  current: ref(null),
  previous: ref(null),
  next: ref(null),
  position: ref(null),
  count: ref(null),
  setStore: () => {},
  setNavigation: () => {},
  clearStore: () => {},
};

/*
useNavigation is a wrapper around useCollectionStore that provides a more specific API for navigating through a collection of items.
If you wanna add a new navigation:
1. add a new type to NavigationTarget (just below)
2. add a new case to the switch statement (at the end of the composable)
3. create a new Store in the return statement of useCollectionStore
Ex: {talentsStore: buildCollectionStore(talentsIdList)}
4. In the parent list component, extract and call setStore by passing a list of ids
5. Use the Navigation.vue component OR Extract setNavigation, previous and next (+ other properties you may need), call setNavigation and use previous/next in nuxt links
*/

export type NavigationTarget = "talents";

export const useNavigation = (target: NavigationTarget): Navigation => {
  const buildNavigator = (store: CollectionStore) => {
    const previous = ref<string | null>(null);
    const next = ref<string | null>(null);
    const current = ref<string | null>(null);
    const position = ref<number | null>(null);
    const count = ref<number | null>(null);
    const setStore = (talents: string[]) => store.set(talents);
    const setNavigation = () => {
      onMounted(() => {
        current.value = store.getCurrent();
        if (current.value) {
          previous.value = store.getPrevious(current.value);
          next.value = store.getNext(current.value);
          position.value = store.getPosition(current.value);
          count.value = store.getCount();
        }
      });
    };

    const clearStore = () => {
      store.clear();
      previous.value = null;
      next.value = null;
      position.value = null;
      count.value = null;
    };

    return {
      current,
      previous,
      next,
      position,
      count,
      setStore,
      setNavigation,
      clearStore,
    };
  };

  switch (target) {
    case "talents": {
      const { talentsStore } = useCollectionStore();
      return buildNavigator(talentsStore);
    }
    // Otherwise we return an empty Navigation object
    default:
      return emptyNavigation;
  }
};

export const toSinglePage = function (route: RouteLocationRaw): RouteLocationRaw {
  const currentRoute = useRoute();

  if (typeof route === "string") return { path: route, query: { from: currentRoute.fullPath } };
  else return { ...route, query: { ...(route.query ?? {}), from: currentRoute.fullPath } };
};
