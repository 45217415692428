import { createGlobalState } from "@vueuse/core";

export interface CollectionStore {
  list: Ref<string[]>;
  getNext: (currentId: string) => string | null;
  getPrevious: (currentId: string) => string | null;
  clear: () => void;
  set: (ids: string[]) => void;
  getCurrent: () => string | null;
  getPosition: (id: string) => number;
  getCount: () => number;
}

export const useCollectionStore = createGlobalState(() => {
  const talentsIdList = ref<string[]>([]);

  const buildCollectionStore = (idList: Ref<string[]>): CollectionStore => {
    const getNext = (currentId: string) => {
      const currentIndex = idList.value.indexOf(currentId);
      if (currentIndex === -1) return null;
      return idList.value[currentIndex + 1];
    };
    const getPrevious = (currentId: string) => {
      const currentIndex = idList.value.indexOf(currentId);
      if (currentIndex === -1) return null;
      return idList.value[currentIndex - 1];
    };
    const clear = () => {
      idList.value = [];
    };
    const set = (ids: string[]) => {
      clear();
      idList.value = ids;
    };
    const getCurrent = () => {
      const route = useRoute();
      if (!route.params.id) return null;
      if (!route.path.includes("/")) return null;
      return route.params.id as string;
    };
    const getPosition = (id: string) => {
      return idList.value.indexOf(id);
    };
    const getCount = () => {
      return idList.value.length;
    };
    return {
      list: idList,
      getNext,
      getPrevious,
      clear,
      set,
      getCurrent,
      getPosition,
      getCount,
    };
  };

  return { talentsStore: buildCollectionStore(talentsIdList) };
});
